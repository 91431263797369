import React from 'react'
import { Link, navigate } from 'gatsby'

import { PATH } from '../../constants'
import spoonxLogo from '../../images/green-logo.png'

import './style.scss'

const linkList = [
  {
    title: 'Learn with us',
    links: [
      {
        text: 'Case Studies',
        link: PATH.ourWork,
      },
    ],
  },
  {
    title: 'Grow with us',
    links: [
      {
        text: 'Social Media Management',
        link: PATH.socialMediaManagement,
      },
      {
        text: 'Influencer Marketing',
        link: PATH.influencerMarketing,
      },
      {
        text: 'Generative AI Solutions',
        link: PATH.generativeAISolutions,
      },
    ],
  },
  {
    title: 'Work with us',
    links: [
      {
        text: 'Careers',
        link: PATH.careers,
      },
    ],
  },
]

const Footer = () => {
  return (
    <footer className="footer-wrapper">
      <h1 className="ghost-page-header">footer</h1>
      <div className="footer-container">
        <div className="main-box">
          <img className="spoonx-logo" src={spoonxLogo} alt="spoonx-logo" />
          <div className="main-container">
            <div className="link-list-container">
              {linkList.map(item => (
                <div key={item.title} className="column">
                  <h4 className="footer-title">{item.title}</h4>
                  <ul>
                    {item.links.map(link => (
                      <li key={link.text}>
                        <Link to={link.link}>{link.text}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className="book-your-container">
              <span>Ready to connect and engage with your customers?</span>
              <button onClick={() => navigate(PATH.getStarted)}>
                Book Your Free Consultation
              </button>
            </div>
          </div>
        </div>

        <div className="divider"></div>
        <div className="policy-box">
          <div className="text-container">
            <div className="text-box">
              <Link to={PATH.termsOfService}>Terms and Conditions</Link> ｜{' '}
              <Link to={PATH.privacyPolicy}>Privacy Policy</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
